import React, { useEffect } from "react";
import Breadcrumb from "../Breadcrumb";
import Section from "../Section";
import AboutUsSection from "../Section/AboutSection";
import ServiceSection from "../Section/ServiceSection";
import TeamSection from "../Section/TeamSection";
import TestimonialSection from "../Section/TestimonialSection";
import { pageTitle } from "../../helpers/PageTitle";
import { useInformation } from "../../apiCalling/useInformatio";
import config from "../../config";
import { BasicInformation } from "../../constatnts/information";
import { useSpeech } from "../../apiCalling/useSpeech";

export default function AboutPage() {
	pageTitle("About Us");
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const { data } = useInformation();
	const { data: speech } = useSpeech();
	const groupedItems = speech?.reduce((acc, item) => {
		const group = item.type == "Director" ? 'Director' : 'Admin';
		if (!acc[group]) {
			acc[group] = [];
		}
		acc[group].push(item);
		return acc;
	}, {});

	var BreadcrumbImage = "/images/page_header_1.jpeg";
	if (data?.pageBannerLink)
		BreadcrumbImage =config.baseUrl+ data?.pageBannerLink;
	console.log("useConcernMenu--", data);
	return (
		<>
			<Breadcrumb title="About Us" bgUrl={BreadcrumbImage} />
			<Section pt="0" ptLg="50" pb="50" pbLg="50" className="position-relative">
				<div className="container">
					
					<p className="text-justify" dangerouslySetInnerHTML={{ __html: data?.aboutUs || '' }} />
				</div>
			</Section>
			<Section pt="50" ptLg="50" pb="50" pbLg="50" className="position-relative">
				<div className="container">
					<div className="row justify-content-md-center">
						{groupedItems && groupedItems.Director && (
							groupedItems.Director?.map((item, index) => (
								<div className="col-md-6 col-sm-12">
									<div className="cs_testimonial_img text-center cs_mb_15">
										<img
											src={config.baseUrl + item.imgLink}
											alt={item.name}
											className="cs_height_75 cs_width_75 rounded-circle"
										></img>
									</div>
									<h3 className="text-center cs_fs_18 cs_mb_2 cs_lh_base">{item.name}</h3>
									<p className="text-center m-0 cs_fs_14 cs_lh_base">{item.designation}</p>
									<div className="pt-10">
										<p className="text-justify">{item.description}</p>
									</div>
								</div>
							))
						)}
					</div>
				</div>
			</Section>

			<Section pt="0" ptLg="50" pb="50" pbLg="50" className="position-relative">
				<div className="container">
					<h2 className="text-accent text-center">History of {BasicInformation.companyName} </h2>
					<p className="text-justify" dangerouslySetInnerHTML={{ __html: data?.companyHistory || '' }} />
				</div>
			</Section>

			{/* <Section pt="140" ptLg="80" pb="130" pbLg="70" className="bg-primary">
        <ServiceSection
          data={serviceData}
          sectionTitleDown="<span className='text-accent'>Digital agency</span> services built specifically for your business"
          sectionBtnText="Find More Services"
          sectionBtnUrl="/service"
          textVariant="text-white"
        />
      </Section>

      <Section pt="133" ptLg="75">
        <TeamSection
          data={teamData}
          sectionTitle="Meet the professional team <br/>behind the success"
          sectionTitleUp="Meet Our Team Member"
        />
      </Section>

      <Section
        pt="110"
        ptLg="55"
        pb="140"
        pbLg="80"
        style={{ backgroundImage: `url(${'/images/testimonial_bg.jpeg'})` }}
      >
        <TestimonialSection
          sectionTitleUp="Testimonial"
          sectionTitle="What They’re Saying?"
          sectionSubTitle="Providing legal advice, contract drafting, compliance assistance, intellectual property protection, and other legal support for businesses."
          data={testimonialData}
        />
      </Section> */}
		</>
	);
}
