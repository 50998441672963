import React, { useEffect } from "react";
import Section from "../Section";
import { pageTitle } from "../../helpers/PageTitle.js";
import Breadcrumb from "../Breadcrumb/index.jsx";
import { useCorporateValues } from "../../apiCalling/useCorporateValues.js";
import SectionHeadingStyle2 from "../SectionHeading/SectionHeadingStyle2.jsx";
import config from "../../config/index.js";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useInformation } from "../../apiCalling/useInformatio.js";

export default function CorporateValuesPage() {
	pageTitle("Export");
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const { data } = useCorporateValues();
	console.log("CorporateValues--", data);

	const { data:information } = useInformation();
	var BreadcrumbImage = "/images/page_header_1.jpeg";
	if (information?.pageBannerLink)
		BreadcrumbImage = config.baseUrl + information?.pageBannerLink;
	return (
		<>
			<Breadcrumb title="Export" bgUrl={BreadcrumbImage} />
			<Section pt="50" ptLg="50" pb="50" pbLg="50">
				<div className="container">
					{/* <SectionHeadingStyle2 sectionTitleUp="Our Export" sectionTitle="Find Out Export" /> */}
					<div className="row">
						{data?.map((item, index) => (
							<React.Fragment key={index}>
								<h3>{item.name}</h3>
								{/* <p className="text-justify">{item.description}</p> */}
								<p className="text-justify" dangerouslySetInnerHTML={{ __html: item?.description || '' }} />
								<div className="row justify-content-md-center">
									{item?.reportDetailsVM?.map((detail, detailIndex) => (
										<div className="col-md-4">
											<div class="box-shadow p-3 bg-body rounded">
												<a target="_blank" href={`${config.baseUrl}${detail.imgLink}`}>
													<p className="cs_fs_20 cs_mb_5">
														<Icon icon="ic:outline-cloud-download" />	{detail.name}
													</p>
												</a>
											</div>
										</div>
									))}
								</div>
							</React.Fragment>
						))}
					</div>
				</div>
			</Section>
		</>
	);
}
